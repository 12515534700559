body {
    margin: 0;
    background-color: #090446;
}



#root {
    overflow-x: hidden !important;

}

.font-color-normal {
    /* color: #D4ECF3; */
    color: #86A8E7 !important;
}

.font-color-call-to-action {
    /* color: #D1C0AA !important; */
    color: #5FFBF1 !important
}

.font-color-for-topics {
    color: #E0E0E8 !important
}

.primary-color {
    background-color: #090446;
}

:root {
    --margin-top-value: 0;
}

.forced-margin-top {
    margin-top: var(--margin-top-value) !important;
}